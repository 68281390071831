/* width */
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(197, 197, 197);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(216, 216, 216);
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #6c76ff !important;
}

.input-group-v1 label {
  left: 36px;
  transition: all .2s cubic-bezier(0, 0, .2, 1);
}

.LocalTab-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.LocalTab-root {
  text-transform: none;
  padding: 6px 12px;
  overflow: hidden;
  position: relative;
  font-size: 0.8125rem;
  max-width: 264px;
  box-sizing: border-box;
  min-height: 48px;
  text-align: center;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.75;
  white-space: normal;
  letter-spacing: 0.02857em;
}

.form-group {
  position: relative;
  text-align: center;
  margin-top: 1rem;
  padding: 1rem
}

.input-group-v1 .input-group-icon {
  position: absolute;
  left: 0;
  bottom: 4px;
  font-size: 24px;
}

.form-v1 .form-group {
  padding: 0;
}

.input-group-v1 input {
  padding-left: 36px;
}

.popUpButton .MuiButton-label {
  position: relative;
}

.blButton {
  min-width: 3rem !important;
}

.cicloButton {
  margin: 0 0 0 .5rem !important;

}

.back-drop {
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
}

.in-back-drop {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.back-quest {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div-select-f {
  width: 34rem;
  max-width: 100%;
  display: grid;
  grid-template-columns: 15rem 4rem 15rem;
  height: calc(100% - 5rem);
}

/*

.back-quest {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.div-select-f {
  width: 34rem;
  max-width: 100%;
  position: absolute;
  margin-left: -17rem;
  left: 50%;
  display: grid;
  grid-template-columns: 15rem 4rem 15rem;
  height: calc(100% - 2rem);
}

*/

.div-select {
  background-color: black;
  opacity: .3;
  width: 100%;
  height: 100%;
  position: absolute;
}

.page-filter {
  display: grid;
  height: 100%;
  grid-template-columns: 2.5rem auto 2.5rem;
}

.MuiTableCell-sizeSmall {
  padding: 6px 16px 6px 16px !important;
}

/*****************************************************/
.MuiSlider-markLabel {
  font-size: 0.75rem !important;
}

/*.leaflet-container {
  height: 38rem;
  min-width: 20rem;
  border: 1px solid rgb(233, 233, 233);
}*/

.leaflet-edit-marker-selected {
  border: 4px dashed orange !important;
}

.sr-only {
  display: none;
}

.checkMap {
  top: -9px !important;
}

.checkMap:hover {
  background-color: transparent !important;
}

.leaflet-div-icon {
  border: 2px solid #666 !important;
  border-radius: 35px !important;
  height: 15px !important;
  width: 15px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
}

.Edit-textField-46 {
  margin-left: 0 !important;
}

.tool-tip-size24-L {
  font-size: 24px;
}

.tool-tip-size16-L {
  font-size: 16px;
}

.tool-tip-size10-L {
  font-size: 10px;
}

.tool-tip-L {
  background: transparent;
  border: none;
  box-shadow: none;
  font-weight: bold;
  font-family: "Roboto";
  text-shadow:
    -1px -1px 1px rgb(255, 255, 255, .25),
    1px -1px 1px rgb(255, 255, 255, .25),
    -1px 1px 1px rgb(255, 255, 255, .25),
    1px 1px 1px rgb(255, 255, 255, .25),
    1px 0px 1px rgb(255, 255, 255, .25),
    -1px 0px 1px rgb(255, 255, 255, .25),
    0px 1px 1px rgb(255, 255, 255, .25),
    0px -1px 1px rgb(255, 255, 255, .25);
}

.tool-tip-H {
  font-size: 15px;
  background: transparent;
  border: none;
  box-shadow: none;
  font-weight: bold;
  font-family: "Roboto";
  text-shadow:
    -1px -1px 1px rgb(0, 0, 0, .25),
    1px -1px 1px rgb(0, 0, 0, .25),
    -1px 1px 1px rgb(0, 0, 0, .25),
    1px 1px 1px rgb(0, 0, 0, .25),
    1px 0px 1px rgb(0, 0, 0, .25),
    -1px 0px 1px rgb(0, 0, 0, .25),
    0px 1px 1px rgb(0, 0, 0, .25),
    0px -1px 1px rgb(0, 0, 0, .25);
}


.popUpF {
  max-height: 100%;
  display: grid;
  grid-template-rows: minmax(4rem, 100%) 1.5rem;
}

.bLine {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.bLineO {
  border-bottom: 1px solid rgba(0, 0, 0, 0.22);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.titleTextRel {
  text-align: left;
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: .75;
  letter-spacing: 0.00938em;
}

.contentTextRel {
  display: inline-flex;
  height: 2.4rem;
  font: inherit;
  width: 100%;
  padding: 6px 0 7px;
}

.contentValueRel {
  font-size: 13px;
  display: flex;
  align-items: center;
  width: 8rem;
}

.contentPercRel {
  font-size: 13px;
  display: flex;
  align-items: center;
  width: 3.6rem;
}

.spaceListRight {
  width: 3rem;
  text-align: right;
}

.spaceListLeft {
  width: 3rem;
  text-align: left;
}

.menuBot {
  margin-bottom: -14rem;
  -webkit-transition: all .1s linear;
  /* For Safari 3.1 to 6.0 */
  transition: all .1s linear;
}

.outThere {
  margin-top: 0 !important;
}

.fixedTotal {
  position: fixed;
  top: 64px;
  left: 256px;
  -webkit-transition: all .2s linear;
  /* For Safari 3.1 to 6.0 */
  transition: all .2s linear;
}

.scrollItens {
  margin-left: 25rem;
  height: 100%;
  overflow-y: auto;
}

.autoDiv {
  overflow-y: auto;
  max-height: 20rem;
  min-height: 1rem;
  width: 100%;
  background-color: white;
  position: absolute;
  z-index: 999;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0.2rem;
}

.popUpInput {
  width: 4.5rem;
  border: none;
  border-bottom: solid 1px #aaa;

}

.popUpInput:focus {
  border: none;
  border-bottom: solid 1px #aaa;
  outline: #aaa;
  -webkit-appearance: none;
}

.popUpInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tableList {
  background-color: white;
}

.active,
.tableList:hover,
.accordion:hover {
  background-color: #f1f1f1 !important;
}

.panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.textButtom {
  margin: 0 0.2rem 0.5rem 0.2rem !important;
  font-size: .8rem !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  min-width: 1rem !important;
  min-height: 1rem !important;
  width: 2.5rem !important;
  height: 2.3rem !important;
  color: white !important;
}

.MuiDialog-paperFullWidth {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: 0 0 0 0 !important;
}

@media screen and (max-width: 1024px) {
  .fixedTotal {
    left: 1rem;
  }
}

@media screen and (max-width: 785px) {
  .fixedTotal {
    position: relative;
    margin-top: -4rem;
    margin-bottom: 4rem;
  }

  .scrollItens {
    margin-left: 1rem;
  }
}

.nav-item:disabled span {
  color: #7D7D7D;
}

.MuiInputLabel-shrink {
  transform: scale(1) !important;
  font-size: 12px !important;
}

.dashboard-card {
  position: relative;
  min-width: 16rem;
  min-height: 12rem;
  height: 100%;
  transition: box-shadow 0.3s ease;
}

.dashboard-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dashboard-card {
  gap: 1rem;
  border-radius: .8rem;
  background-color: #343a40;
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
}

.dashboard-card .cardheader {
  text-align: left;
  color: white;
  display: flex;
  padding: 0.5rem;
}

.dashboard-card .cardheader .title {
  height: 1.7rem;
  line-height: 1.7rem;
  vertical-align: super;
  padding-left: .3rem;
}

.dashboard-card .cardmiddle {
  display: flex;
  gap: .5rem;
  justify-content: center;
  margin-bottom: 2rem;
}

.dashboard-card .cardmiddle .cardmiddle-legend {
  width: 100%;
  position: absolute;
  color: #fa7d34;
}

.dashboard-card .cardmiddle .cardmiddle-value {
  color: #fa7d34;
  font-size: 3.5rem;
  margin-block-end: auto;
}

.dashboard-card .cardfooter {
  display: flex;
  justify-content: center;
  margin: -1rem;
  border-block-start: solid .2rem #343a40;
  position: absolute;
  width: 100%;
  bottom: 1rem;
}

.dashboard-card .cardfooter .cardfooter-content {
  background-color: #343a40;
  color: #FFF;
  width: 100%;
  height: 3rem;
  font-size: .7rem;
  padding-top: .3rem;
  text-align: center;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-se:after {
  border-color: white;
  border-right: 0.25rem solid white;
  border-bottom: 0.25rem solid white;
  width: 0.75rem;
  height: 0.75rem;
  bottom: 0.25rem;
  right: 0.25rem;
}

.react-grid-item.react-grid-placeholder {
  background-color: #fa7d34;
  border-radius: .8rem;
  opacity: 0.5;
}