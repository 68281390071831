.anime-close {
    height: 0 !important;
}

.anime {
    -webkit-transition: all .2s ease-in-out;
    /* For Safari 3.1 to 6.0 */
    transition: all .2s ease-in-out;
    overflow: hidden !important;
}
