.gridContainer {
    grid-gap: 0.5rem;
    margin: 1rem 0;
    justify-content: center;
}

.modulesButton {
    display: flex;
    justify-content: flex-start;
    padding: 0 0.5rem;
    width: 10rem;
}

.modulesButton span:first-child {
    color: rgb(250, 125, 52);
}

.modulesButton span:nth-child(2) {
    font-size: 1rem;
}

.modulesContainer {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 1rem;
}

.modulesTitle {
    text-align: start;
    color: rgb(250, 125, 52);
}

.noModulesTitle {
    text-align: center;
    color: white;
    padding: 4rem 0;
}

.pageTitleContainer {
    margin-bottom: 1rem;
}

.pageTitle {
    margin: 0.25em 0;
}

.titleListStations {
    margin: 1rem;
    white-space: nowrap;
    width: 13.75rem;
    color: rgb(250, 125, 52);
}

.bodyListStations {
    overflow: auto;
    height: 100%;
}

.containerListStations {
    max-height: 20rem;
}

.stationDataModal {
    max-height: 36rem;
    width: 60rem;
    padding: 0;
}

.modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    background-color: #2D3238;
    position: sticky;
    top: 0;
    z-index: 999;
}

.leftDivHeader {
    width: 33%;
    text-align: left;
}

.centerDivHeader {
    width: 33%;
    text-align: center;
}

.rightDivHeader {
    width: 33%;
    text-align: right;
}

.leftDivBody {
    width: 10%;
    text-align: left;
}

.centerDivBody {
    width: 80%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rightDivBody {
    width: 10%;
    text-align: right;
}

.titleStationDataModal {
    color: white;
    margin: 0;
}

.configurationDataContainer {
    display: flex;
    flex-direction: column;
}

.selectStationContainer {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
}

.selectStation {
    background: transparent;
    text-align: center;
    border: none;
    border-bottom: 1px solid black;
    cursor: pointer;
    width: 12.5rem;
    align-self: flex-end;
    padding: 0.5rem 0;
}

.selectStation:hover {
    background: transparent;
    text-align: center;
    border: none;
    border-bottom: 2px solid black;
    cursor: pointer;
}

.selectModulesContainer {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
    font-size: larger;
    overflow: auto;

    @media screen and (max-width: 1600px) {
        margin: 0 1rem;
    }
}

.selectModules {
    background: transparent;
    text-align: center;
    border: none;
    border-bottom: 1px solid black;
    cursor: pointer;
    padding: 0.5rem 0;
    font-size: larger;
}

.selectModules:hover {
    background: transparent;
    text-align: center;
    border: none;
    border-bottom: 2px solid black;
    cursor: pointer;
}

.selectModulesDisabled {
    background: transparent;
    text-align: center;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    padding: 0.5rem 0;
    font-size: larger;
}

.dateContainer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 0.5rem 0;
}

.dateButton {
    height: 24px;
    background: rgb(250, 125, 52);
    width: 68px;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    line-height: 0;
}

.dateButtonDisabled {
    margin: 0.5rem;
    background-color: lightgray;
    border: none;
    padding: 0 0.5rem;
    font-weight: 400;
    color: white;
    border-radius: 0.5rem;
    font-size: medium;
}

.locationContainer {
    padding: 0.5rem 0;
}

.chartContainer {
    margin: 1rem;
}

.restartFilterButton {
    padding: 0.5rem;
    border: none;
    font-weight: 400;
    color: white;
    border-radius: 0.5rem;
    font-size: medium;
    cursor: pointer;
}

.inputDate input:first-child {
    text-align: center;
}

.modulesIcon {
    height: 2.5rem;
    width: 2.5rem;
}

.textModalStation input:first-child {
    text-align: center;
    padding-top: 0.75rem;
}

.dynamicWeatherInformationContainer {
    display: flex;
    justify-content: space-between;
    margin: 1rem;
}

.weatherInformationContainer {
    margin: 1rem;
    display: flex;
    flex-direction: column;
}

.weatherInformationLabel {
    text-align: start;
    font-size: 0.875rem;
}

.weatherInformationInput {
    border: none;
    border-bottom: 1px solid gray;
    text-align: center;
    font-size: 1rem;
    width: 24rem;
}

.weatherCard {
    background-color: rgb(52, 58, 64);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 9rem;
    width: 12.75rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin: 0.5rem 0.5rem 0.5rem 0;
}

.weatherData {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 8rem;
    width: 11.75rem;
    margin: 0.5rem 0;
    border-right: 1px solid gray;
}

.weatherInformationInput:hover {
    border-bottom: 2px solid black;
}

.weatherListData,
.inputWeatherForecast {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

input:checked~.weatherListData {
    max-height: 210rem;
}

.weatherListItem {
    list-style: none;
    margin: 0.5rem 0;
    font-size: 1.3rem;
    display: flex;
    flex-direction: column;
}

.weatherListContainer {
    max-height: 20rem;
}

.forecastInformation {
    list-style: none;
    margin: 0.5rem 0;
    color: white;
}

.labelWeatherForecast {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding: 0 0.5rem;
}

.titleHistoric {
    padding: 0.25rem 1rem 0 1rem;
    margin: 0;
    white-space: nowrap;
    color: white;
    font-size: 1.5rem;
}

.titleWeatherForecast {
    padding: 0 1rem 0 1rem;
    margin: 0;
    white-space: nowrap;
    width: 100%;
    color: white;
    font-size: 1.5rem;
}

.arrowIcon {
    display: inline-block;
    color: rgb(250, 125, 52);
}

.inputWeatherForecast+.labelWeatherForecast .arrowIcon {
    display: inline-block;
    transition: all 0.6s;
    transform: rotate(90deg);
}

.inputWeatherForecast:checked+.labelWeatherForecast .arrowIcon {
    display: inline-block;
    transform: rotate(-90deg);
}

.windDirectionIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem;
}

.circle {
    position: relative;
    width: 3.5rem;
    height: 3.5rem;
    border: 2px dotted white;
    border-radius: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.windDirection {
    position: absolute;
    font-size: 0.8rem;
}

.north {
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
}

.south {
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
}

.east {
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
}

.west {
    left: 2px;
    top: 50%;
    transform: translateY(-50%);
}

.windDirectionArrow {
    margin-top: -3rem;
    margin-bottom: 1rem;
    transition: transform 0.3s ease-in-out;
}

.listItem {
    border-left: 0.5rem solid white !important;
    background-color: rgb(250, 125, 52) !important;
    color: white !important;
}

.zoom {
    position: absolute;
    z-index: 401;
    width: 3.7rem;
    padding: 0.25rem;
    top: 0;
}

.containerToLocate {
    padding: 1rem;
    z-index: 997;
    left: 4rem;
    box-shadow: 2px 2px 5px gray;
    top: 4.75rem;
    position: absolute;
    text-align: center;
}

.containerChangeStation {
    padding: 1rem;
    z-index: 997;
    left: 23rem;
    box-shadow: 2px 2px 5px gray;
    top: 4.75rem;
    position: absolute;
    text-align: center;
}

.historyCardContainer {
    display: flex;
    justify-content: space-around;
    overflow-x: auto;
    white-space: nowrap;
}

.historyCard {
    display: inline-block;
    margin: 0 0.1rem;
    background: rgb(32, 35, 39);
    color: white;
    border-radius: 0.5rem;
    text-align: center;
    cursor: pointer;
    width: 4rem;
    border: none;
}