html, body {
  height: 100%;
  width: 100%;
  margin: 0;    
}

.ini {
  min-height: 100vh;
  background-color: #f5f5f5;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.back {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: black;
    opacity: .3;
    z-index: 1002;
}

.sr-only {
  display: none;
}

.header {
  z-index: 600;
  position: fixed;
  width: 100%;
  margin-left: 15rem;
  height: 4rem;
  background-color: white;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.sidenav {
  position: fixed;
  width: 15rem;
  height: 100%;
  background-color: #343a40;
  z-index: 1003;
}

.sidenav-layout {
  display: grid;
  grid-template-rows: 4rem auto 10rem;
}
.sidenav-header {
  line-height: 4rem;
  padding-left: 1.5rem;
  height: 4rem;
  box-shadow: -10px 3px 5px 0px rgba(0, 0, 0, 0.2), -10px 8px 10px 0px rgba(0, 0, 0, 0.14), -10px 3px 14px 0px rgba(0, 0, 0, 0.12);

  padding: .5rem;
  padding-left: 3rem;
}
.sidenav-header a {
    color: #fff;
    font-size: 20px;
    text-decoration: none;
    margin-left: 3rem;
}
.sidenav-footer {

  background-color: rgba(0, 0, 0, 0.01);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.menu {
  overflow-y: auto;
  position: relative;
  height: 100%;
  margin-bottom: -7.75rem;
  -webkit-transition: all .2s linear; /* For Safari 3.1 to 6.0 */
    transition: all .2s linear;  
}
.menu::-webkit-scrollbar {
  width: 4px;
}
.menu::-webkit-scrollbar-track {
  background: rgba(110, 107, 107, 0.541); 
}
.menu::-webkit-scrollbar-thumb {
  background: rgba(179, 179, 179, 0.568); 
}
.menu::-webkit-scrollbar-thumb:hover {
  background: rgba(179, 179, 179, 0.692); 
}

.nav-text {
    color: #fff;
    line-height: 44px;
    opacity: .85;
    font-size: 14px;
}

.iconS {   
    width: 1.5rem !important;
}
.iconM {
  padding-left: .5rem;
  line-height: 4rem;
  color: black;
  width: 2rem !important;
  height: 2rem !important;
}
.menu_icon {
  padding-left: .5rem;
  line-height: 3rem;
  color: black;
  width: 2rem !important;
  height: 3rem !important;
}
.iconMenu {
  font-size: 16px !important;
  width: 16px;
  margin-right: 8px;
}
.wrapper {
  overflow: auto;
  min-height: 99%;
  margin-bottom: -2.75rem;
  margin-left: 15rem;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 4rem auto 1.25rem;
}

.wrapperMap {
  position: relative;
}

.push {
  /*height: 4rem;*/
  height: 100%;
}

.push-bottom {
  /*height: 1.25rem;*/
  height: 100%;
}

.footer {  
  margin-left: 15rem;
  margin-bottom: 0rem;
  text-align: right;
  padding: 11px 30px 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  background-color: rgba(0, 0, 0, 0.01);
}
.footer-text span {
  justify-content: space-between;    
  font-size: 11.2px;
  
  opacity: .87;
  color: rgba(0, 0, 0, 0.87);
}


.header-container {
  display: flex;
  flex-direction: row;
  margin-right: 15rem;
}

.left-header {
  padding-left: 1rem;
}

.icon-pos-top {
  margin-top: .5rem;
}

.icon-pos_sub {
  position: relative;
  height: 100% !important;
}

.header-center {
  width:100%;
}

.header-right {
  line-height: 4rem;
  text-align: right;
  padding-right: 1rem;
  display: inline-flex;
}
.div-list_a{ 
  position: relative;
  overflow-y: auto;
  height: auto;
  width: 15rem;
}
.div-list_h{ 
  position: relative;
  height: calc(100vh - 20rem);
  width: 25rem;
}
.div-list_itens{ 
  height: calc(100vh - 21rem);
  width: 23.5rem;
}
.div-list_box{
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 20rem);
  width: 24.8rem;
}
.div-list_itens_list{
  height: calc(100vh - 20rem);
  width: 24.5rem;
}

.list-p{
  display: grid;
  height: 100%;
  grid-template-rows: 4rem auto;
}
.list-p-filter{
  display: grid;
  height: 100%;
}
.list-pT{
  display: grid;
  height: 100%;
  grid-template-rows: 4rem calc(100% - 11rem);
}
.map-p{
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: min-content auto;  
}

/*//////////////////////////////////////*/

.ant-menu-item-selected {
  background-color: transparent !important;
  border: 0 !important;
  padding: 0;
  margin: 0;
}
.ant-menu-item, .ant-menu-submenu-title {
  padding: 0 !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;    
}
.nav-menu,.ant-menu-dark {
  background: #343a40 !important;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 1rem !important;
}

.nav-menu2 {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 1rem !important;
}

.ant-menu-sub {    
  background-color: #2d3238 !important;
}
ul .nav-item {
  padding-left: 2rem;
  color: rgba(255, 255, 255, 0.65) !important;
  
}
ul .nav-item-sub {
  padding-left: 3rem;
}

button.nav-item,
a.nav-item {
  text-decoration: none;
  text-transform: none;
  width: 100%;
  height: 100%;
  justify-content: left;
}

.MuiButton-label span {
  position: absolute;
  left: 4rem;
  top: .5rem;
}

.header, .header-container, .footer, .wrapper, .sidenav {
  transition: all .3s;
}

.sidenavShow {
  transform: translateX(0rem) !important;
  
}

a.list-inline-item {
 color: rgba(0, 0, 0, 0.65);
}
.user-top {
  right: 1rem;
  text-align: center;
  
}

a.list-inline-item:hover > div {
  color: rgba(0, 0, 0, 0.65);
  background-color: #f7f7f7 !important;
}

/*************/
.popup-list {
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
}

.popup-item {
  font-size: .875rem !important;
  height: auto !important;
  text-align: center !important;
  padding: .5rem !important;
  color: rgba(0,0,0,.87) !important;
}

.divider {
  border-width: 1px 0 0;
  border-style: solid;
  border-color: rgba(0,0,0,.1);
}

.linha-pop {
  color: rgba(0,0,0,.87) !important;
  width: 5rem;
  height: 2rem !important;
}

a.linha-pop:hover {
  color: rgba(0,0,0,.87);
}
.icon-fix {
  font-size: 1rem !important;
}

.div-list-in {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 15rem;
  overflow-y: auto;
}

.modalPanel {
  display: grid;
  gap: 1rem;
  height: 100%;
  margin-top: -1rem;
}

@media screen and (max-width: 1024px) {
  .header, .header-container, .footer, .wrapper {
      margin-left: 0;
      margin-right: 0;
  }
  .sidenav {
      transform: translateX(-15rem);
  }
  .hideS {
      display: block;
  }
}
@media screen and (min-width: 1025px) {
  .back {
      display: none;
  }

  .hideS {
      display: none;
  }
  
}
@media screen and (max-width: 870px) {
  .map-p{
    grid-template-rows: min-content auto;
  }  

  .div-list_box{
    height: calc(100vh - 24rem);
  }
  .div-list_itens{
    height: calc(100vh - 22rem);
  }
  .div-list_h{ 
    height: calc(100vh - 22rem);
  }
}