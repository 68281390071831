.lay_top {
  position: fixed;
  top: 5.5rem;
  left: 17rem;
  width: calc(100% - 19rem);
  z-index: 12;
}

.lay_side {
  margin-top: 11.3rem;
  width: 25rem;
  height: 47rem;
}

.lay_side_top_d0 {
  position: fixed;
  top: 12.5rem;
  left: 17rem;
  width: 25rem;
  height: 5rem !important;
  padding: .5rem;
  height: 4rem;
  overflow-y: hidden;
  z-index: 10
}

.lay_side_top_d1 {
  position: fixed;
  top: 0;
  left: 16.5rem;
  width: 26rem;
  height: 16.3rem;
  background-color: #f5f5f5;
  z-index: 9
}

.lay_side_top_d2 {
  position: fixed;
  top: 17.1rem;
  left: 17rem;
  width: 25rem;
  height: .5rem;
  background-color: white;
  z-index: 12;
}

.lay_map {
  position: fixed;
  top: 12.5rem;
  left: 43rem;
  width: calc(100% - 45rem);
  height: calc(100% - 15.8rem);
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}